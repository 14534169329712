<template>
  <div>
    <div v-if="mode === 'DESIGN'">
      <el-button size="small" icon="el-icon-paperclip" round
        >选择文件</el-button
      >
      <ellipsis
        :row="1"
        :content="placeholder + sizeTip"
        hoverTip
        slot="tip"
        class="el-upload__tip"
      />
    </div>
    <div v-else-if="mode === 'PC' && !readonly" v-loading="loading">
      <!--  :multiple="maxNumber > 1" -->
      <el-upload
        :file-list="fileList"
        :action="uploadUrl"
        :limit="maxNumber"
        with-credentials
        :data="uploadParams"
        :on-success="uploadSuccess"
        auto-upload
        :before-upload="beforeUpload"
        :on-remove="handleRemove"
        :on-error="uploadFail"
        :accept="String(fileTypes)"
        :on-exceed="overLimit"
      >
        <el-button size="small" icon="el-icon-paperclip" round
          >选择文件</el-button
        >
        <ellipsis
          :row="1"
          :content="placeholder + sizeTip"
          hoverTip
          slot="tip"
          class="el-upload__tip"
        />
      </el-upload>
    </div>
    <!--  :multiple="maxNumber > 1" -->
    <div v-else-if="mode === 'MOBILE' && !readonly">
      <uploader
        v-model="fileList"
        :accept="String(fileTypes)"
        :max-count="maxNumber > 0 ? maxNumber : 99"
        deletable
        :before-delete="handleRemove"
        upload-text="选择文件"
        :after-read="afterRead"
        :max-size="maxSize * 1024 * 1024"
        @oversize="onOversize"
        upload-icon="description"
      >
      </uploader>
      <div style="color: #9b9595">{{ placeholder }} {{ sizeTip }}</div>
    </div>
    <div v-else class="file-preview">
      <div v-if="mode === 'PC'" class="file-preview-pc">
        <div v-for="file in _value" :key="file.id">
          <ellipsis
            class="file-item"
            type="primary"
            @click.native="download(file)"
            :content="file.name"
          >
            <i slot="pre" class="el-icon-document"></i>
            <el-tag slot="aft" size="mini">{{ getSize(file.size) }}</el-tag>
          </ellipsis>
        </div>
      </div>
      <div v-else>
        <el-row v-for="file in _value" :key="file.id">
          <el-col :span="20">
            <ellipsis
              style="display: inline-block"
              class="file-item"
              type="primary"
              @click.native="download(file)"
              :content="file.name"
            >
              <i slot="pre" class="el-icon-document"></i>
            </ellipsis>
          </el-col>
          <el-col :span="4">
            <el-tag size="mini">{{ getSize(file.size) }}</el-tag>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <el-dialog title="文件预览" :visible.sync="fileShow" width="100%">
      <div class="div" @click="sendOpen">发消息预览文件</div>
      <div class="div" @click="showFileHandle">预览文件</div>
      <div class="uni" @click="uniOpenHandel">uni打开试试</div>
      <a :href="iframeUrl">预览a标签的</a>
      <div class="div">
        <iframe
          :src="iframeUrl"
          id="iframeBox"
          ref="iframeRef"
          frameborder="0"
          style="width: 100%; height: 62vh"
        ></iframe>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fileShow = false">取 消</el-button>
        <el-button type="primary" @click="fileShow = false">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import componentMinxins from "../ComponentMinxins";
import { Uploader, Toast } from "vant";
import wx from "weixin-js-sdk";
import uniWebview from "@dcloudio/uni-webview-js";
export default {
  mixins: [componentMinxins],
  name: "FileUpload",
  components: { Uploader },
  props: {
    placeholder: {
      type: String,
      default: "请选择附件",
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    maxNumber: {
      type: Number,
      default: 10,
    },
    fileTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    sizeTip() {
      if (this.fileTypes.length > 0) {
        return ` | 只允许上传[${String(this.fileTypes).replaceAll(
          ",",
          "、"
        )}]格式的文件，且单个附件不超过${this.maxSize}MB`;
      }
      return this.maxSize > 0 ? ` | 单个附件不超过${this.maxSize}MB` : "";
    },
    fileList: {
      get() {
        return this._value.map((f) => {
          return {
            name: f.name,
            url: this.$getRes(f.url),
            status: "success",
            file: new File([], f.name, {}),
          };
        });
      },
      set(val) {
        console.log("*********", val);
      },
    },
  },
  data() {
    return {
      loading: false,
      fileShow: false,
      iframeUrl: "",
      disabled: false,
      catchList: [],
      uploadUrl: `${this.BASE_URL}/wflow/res`,
      uploadParams: { isImg: false },
    };
  },
  created() {
    if (!this.value) this.value = []
  },
  methods: {
    beforeUpload(file) {
      if (Array.isArray(file)) {
        for (let i = 0; i < file.length; i++) {
          if (!this.validFile(file[i])) {
            return false;
          }
        }
        return true;
      } else {
        return this.validFile(file);
      }
    },
    validFile(file) {
      if (this.maxSize > 0 && file.size / 1024 / 1024 > this.maxSize) {
        this.$message.warning(`单个文件最大不超过 ${this.maxSize}MB`);
      } else {
        this.loading = true;
        return true;
      }
      return false;
    },
    getSize(size) {
      if (size > 1048576) {
        return (size / 1048576).toFixed(1) + "MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(1) + "KB";
      } else {
        return size + "B";
      }
    },
    removeFile(fileId) {
      this.$axios.delete(`${this.BASE_URL}/wflow/res/${fileId}`).then((rsp) => {
        this.$message.success("移除文件成功");
      });
    },
    uploadSuccess(response, file, fileList) {
      this.loading = false;
      this.catchList.push(response);
      let ft = fileList.filter((f) => f.size !== undefined);
      if (this.catchList.length === ft.length) {
        this.$emit("input", this.catchList);
      }
      this.$message.success(response.name + "上传成功");
    },
    uploadFail(err) {
      console.log(err);
      this.loading = false;
      this.$message.error("文件上传失败 " + err);
    },
    overLimit() {
      if (this.mode === "PC") {
        this.$message.warning("最多只能上传" + this.maxNumber + "个附件");
        this.loading = false;
      } else {
        Toast.fail("数量超出限制");
      }
    },
    afterRead(file) {
      if (Array.isArray(file)) {
        file.forEach((fl) => {
          this.uploadFile(fl);
        });
      } else {
        this.uploadFile(file);
      }
    },
    uploadFile(file) {
      //上传文件
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("isImg", false);
      Toast.loading({ message: "上传中...", forbidClick: true });
      this.$axios
        .post(this.uploadUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("wflow-token"),
          },
        })
        .then(
          (res) => {
            if (res.status === 200) {
              this._value.push(res.data);
              this.$emit("input", this._value);
              Toast.success("上传成功");
            }
          },
          (err) => {
            Toast.fail("上传失败");
            console.log(err);
          }
        );
    },
    onOversize(file) {
      Toast.fail("文件过大");
    },
    handleRemove(file, fileList) {
      let i = this._value.findIndex((v) => v.name === file.name);
      if (i > -1) {
        //this.removeFile(this._value[i].id)
        this._value.splice(i, 1);
        this.$emit("input", this._value);
        console.log("删除文件", file);
      }
    },
    handlePictureCardPreview(file) {},
    handleDownload(file) {
      console.log(file);
    },
    showFileHandle() {
      window.open(
        `https://oss.ekaquan.cn/80a9d4872c404ba990814a03b864ffb1.pdf?name=%E5%B7%A5%E5%8D%95%E8%A1%A8%E6%A0%BC.pdf`,
        "_blank"
      );
    },
    sendOpen() {
      uni.postMessage({
        data: { name: this.iframeUrl, flag: "open" },
      });
    },
    uniOpenHandel() {
      uni.downloadFile({
        url: fileUrl,
        success: (downloadResult) => {
          if (downloadResult.statusCode === 200) {
            // 打开文件
            uni.openDocument({
              filePath: downloadResult.tempFilePath,
              success: (openResult) => {
                console.log("文件打开成功", openResult);
              },
              fail: (openError) => {
                console.error("文件打开失败", openError);
              },
            });
          } else {
            console.error("文件下载失败", downloadResult);
          }
        },
        fail: (downloadError) => {
          console.error("文件下载错误", downloadError);
        },
      });
    },
    isWechat() {
      var os = window.navigator.userAgent.toLowerCase();
      if (os.match(/micromessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    download(file) {
      wx.miniProgram.getEnv(function (res) {
        if (res) {
          wx.miniProgram.navigateTo({
            // 小程序路由参数里面不能带问号会被解析
            url:
              "/pages/showFile/showFile?link=" +
              file.url +
              "&fileName=" +
              file.name,
            success: (res) => {
              //  alert(JSON.stringify(res));
            },
            fail: (res) => {
              // alert(JSON.stringify(res));
            },
          });
        } else {
          window.open(`${this.$getRes(file.url)}?name=${file.name}`, "_blank");
        }
      });
    },
    // download(file) {
    // //  window.open(`${this.$getRes(file.url)}?name=${file.name}`, "_blank");
    //   // this.iframeUrl = `${this.$getRes(file.url)}?name=${file.name}`;
    //   //this.iframeUrl = `https://oss.ekaquan.cn/80a9d4872c404ba990814a03b864ffb1.pdf?name=%E5%B7%A5%E5%8D%95%E8%A1%A8%E6%A0%BC.pdf`;
    //   // this.fileShow = true;

    //   // uniWebview.openDocument({
    //   //   filePath: this.iframeUrl,
    //   //   fileType: "pdf",
    //   //   showMenu: true,
    //   //   success: (res) => {
    //   //     console.info(res);
    //   //     wx.showToast({
    //   //       title: "打开成功",
    //   //     });
    //   //     if (onSuccess) onSuccess();
    //   //   },
    //   // });
    //   // uniWebview.downloadFile(this.iframeUrl);
    //   //this.fileShow = true;

    //   // const isWeiXin = function () {
    //   //   var ua = window.navigator.userAgent.toLowerCase();
    //   //   console.log("浏览器类型" + ua);
    //   //   if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //   //     //return true;
    //   //     alert("微信");
    //   //   } else {
    //   //     window.open(`${this.$getRes(file.url)}?name=${file.name}`, "_blank");
    //   //   }
    //   // };

    //   // window.location.href =
    //   //   "https://oss.ekaquan.cn/80a9d4872c404ba990814a03b864ffb1.pdf?name=%E5%B7%A5%E5%8D%95%E8%A1%A8%E6%A0%BC.pdf";
    //   // window.location.href = this.baseURL + res.data.data + '?token=' + localStorage.getItem('authorization');//验证token
    //   //https://oss.ekaquan.cn/80a9d4872c404ba990814a03b864ffb1.pdf?name=%E5%B7%A5%E5%8D%95%E8%A1%A8%E6%A0%BC.pdf
    //   // window.open(`${this.$getRes(file.url)}?name=${file.name}`, '_blank')
    // },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list__item {
  transition: none;
}

.file-item {
  color: @theme-primary;
  cursor: pointer;
}
</style>
